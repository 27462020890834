// ******************************************
// ****** Localization related methods ******
// ******************************************

var Alkami: any = Alkami || {};

if (!Alkami.Localization) {
    (function (alkami: any) {

        class Localization {
            public SiteText: SiteText;

            public constructor() {
                this.SiteText = new SiteText();
            }

            public includeSiteText(siteTextJSON: JSON): void {
                Object.keys(siteTextJSON).forEach((key) => {
                    this.SiteText.set(key, siteTextJSON[key]);
                });
            }
        }

        class SiteText {
            private _map: any;

            public constructor() {
                this._map = {};
            }

            public get(key: string, ...replacements: any[]): string {
                var value: string = "";

                if (this._map.hasOwnProperty(key)) {
                    value = this._map[key];

                    if (replacements.length > 0) {
                        value = value.replace(/{(\d+)}/g, (match: string, index: number) => {
                            if (typeof (replacements[index]) !== 'undefined')
                                return replacements[index].toString();

                            return match;
                        });
                    }
                }

                return value;
            }

            public set(key: string, value: string) {
                this._map[key] = value;

                Object.defineProperty(this, key, {
                    get: () => { return this._map[key] },
                    set: (newValue) => { console.warn('Setting of SiteText values is not honored'); },
                    configurable: true
                });
            }
        }

        alkami.Localization = new Localization();
    })(Alkami);
}